const totalDataRouter = [
  {
    path: '/total-data',
    component: () => import('@/views/total-data')
  },
  {
    path: '/total-data-accept',
    component: () => import('@/views/total-data/accept')
  },
  {
    path: '/total-data-trans',
    component: () => import('@/views/total-data/trans')
  },
  {
    path: '/total-data-unpaid-receive',
    component: () => import('@/views/total-data/unpaid-receive')
  },
  {
    path: '/total-data-unpaid-receive-detail',
    component: () => import('@/views/total-data/unpaid-receive-detail')
  }
]

export default totalDataRouter
