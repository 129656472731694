const settleHandleRouter = [
  {
    path: '/settle-handle',
    component: () => import('@/views/settle-handle')
  },
  {
    path: '/settle-handle-detail',
    component: () => import('@/views/settle-handle/detail')
  },
  {
    path: '/settle-handle-record',
    component: () => import('@/views/settle-handle/record')
  },
  {
    path: '/settle-handle-retrieve',
    component: () => import('@/views/settle-handle/retrieve')
  }
]

export default settleHandleRouter
