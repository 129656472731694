const reviewRouter = [
  {
    path: '/review',
    component: () => import('@/views/review')
  },
  {
    path: '/review-list',
    component: () => import('@/views/review/list')
  },
  {
    path: '/review-detail',
    component: () => import('@/views/review/detail')
  }
]

export default reviewRouter
