const warehouseGoodsRouter = [
  {
    path: '/warehouse-goods',
    component: () => import('@/views/warehouse-goods')
  },
  {
    path: '/warehouse-goods-detail',
    component: () => import('@/views/warehouse-goods/detail')
  },
  {
    path: '/warehouse-goods-filter',
    component: () => import('@/views/warehouse-goods/filter')
  },
  {
    path: '/stock-taking-record',
    component: () => import('@/views/warehouse-goods/record')
  },
  {
    path: '/stock-logs',
    component: () => import('@/views/warehouse-goods/stock-logs')
  }
]

export default warehouseGoodsRouter
