const returnCheckRouter = [
  {
    path: '/return-check',
    component: () => import('@/views/return-check')
  },
  {
    path: '/return-check-detail',
    component: () => import('@/views/return-check/detail')
  }
]

export default returnCheckRouter
