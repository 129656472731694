const promoteSalesRouter = [
  {
    path: '/promote-sales',
    component: () => import('@/views/promote-sales')
  },
  {
    path: '/promote-sales-detail',
    component: () => import('@/views/promote-sales/detail')
  }
]

export default promoteSalesRouter
