const afterReceiptRouter = [
  {
    path: '/after-receipt',
    component: () => import('@/views/after-receipt')
  },
  {
    path: '/after-receipt-record',
    component: () => import('@/views/after-receipt/record')
  }
]

export default afterReceiptRouter
