const financeReportRouter = [
  {
    path: '/finance-report',
    component: () => import('@/views/finance-report')
  },
  {
    path: '/finance-report-detail',
    component: () => import('@/views/finance-report/detail')
  }
]

export default financeReportRouter
