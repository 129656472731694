const transCheckRouter = [
  {
    path: '/trans-check',
    component: () => import('@/views/trans-check')
  },
  {
    path: '/trans-check-detail',
    component: () => import('@/views/trans-check/detail')
  },
  {
    path: '/trans-check-record',
    component: () => import('@/views/trans-check/record')
  },
  {
    path: '/trans-check-record-detail',
    component: () => import('@/views/trans-check/record-detail')
  }
]

export default transCheckRouter
