const businessUnpaidRouter = [
  {
    path: '/business-unpaid',
    component: () => import('@/views/business-unpaid')
  },
  {
    path: '/business-unpaid-detail',
    component: () => import('@/views/business-unpaid/detail')
  },
  {
    path: '/business-unpaid-payment',
    component: () => import('@/views/business-unpaid/payment')
  }
]

export default businessUnpaidRouter
