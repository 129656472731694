const expenseRouter = [
  {
    path: '/expense',
    component: () => import('@/views/expense')
  },
  {
    path: '/expense-list',
    component: () => import('@/views/expense/list')
  }
]

export default expenseRouter
