const goodsReportRouter = [
  {
    path: '/goods-report-price-changed',
    component: () => import('@/views/goods-report/price-changed')
  },
  {
    path: '/goods-report-price-changed-detail',
    component: () => import('@/views/goods-report/price-changed-detail')
  },
  {
    path: '/goods-report-gift',
    component: () => import('@/views/goods-report/gift')
  },
  {
    path: '/goods-report-gift-detail',
    component: () => import('@/views/goods-report/gift-detail')
  }
]

export default goodsReportRouter
