const transCheckRouter = [
  {
    path: '/trans-out',
    component: () => import('@/views/trans-out')
  },
  {
    path: '/trans-out-detail',
    component: () => import('@/views/trans-out/detail')
  },
  {
    path: '/trans-out-business-order',
    component: () => import('@/views/trans-out/business-order')
  },
  {
    path: '/trans-out-record',
    component: () => import('@/views/trans-out/record')
  },
  {
    path: '/trans-out-record-detail',
    component: () => import('@/views/trans-out/record-detail')
  }
]

export default transCheckRouter
