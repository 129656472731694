const goodsReturnRouter = [
  {
    path: '/goods-return',
    component: () => import('@/views/goods-return')
  },
  {
    path: '/goods-return-add',
    component: () => import('@/views/goods-return/add')
  },
  {
    path: '/goods-return-order',
    component: () => import('@/views/goods-return/order')
  }
]

export default goodsReturnRouter
