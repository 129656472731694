const pickingRouter = [
  {
    path: '/picking-warehouse',
    component: () => import('@/views/picking/warehouse')
  },
  {
    path: '/picking',
    component: () => import('@/views/picking')
  },
  {
    path: '/picking-detail',
    component: () => import('@/views/picking/detail')
  }
]

export default pickingRouter
