const stockWarningRouter = [
  {
    path: '/stock-warning',
    component: () => import('@/views/stock-warning')
  },
  {
    path: '/stock-warning-detail',
    component: () => import('@/views/stock-warning/detail')
  }
]

export default stockWarningRouter
