const loadPickRouter = [
  {
    path: '/load-pick',
    component: () => import('@/views/load-pick')
  },
  {
    path: '/load-pick-client',
    component: () => import('@/views/load-pick/client')
  },
  {
    path: '/load-pick-detail',
    component: () => import('@/views/load-pick/detail')
  }
]

export default loadPickRouter
