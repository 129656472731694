import router from './router'
import token from '@/utils/token'

const whiteList = ['/login'] // no redirect whitelist

// 路由守卫
router.beforeEach((to, from, next) => {
  // 保留跳转前的滚动位置
  from.meta.scrollTop = document.documentElement.scrollTop
  const hasToken = token.get()
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})
