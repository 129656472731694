<template>
  <div id="app">
    <remote-css href="https://at.alicdn.com/t/c/font_2101868_s1ud8ba97c.css" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    'remote-css': {
      render(createElement) {
        return createElement('link', { attrs: { rel: 'stylesheet', href: this.href }})
      },
      props: {
        href: { type: String, required: true }
      }
    }
    // 'remote-js': {
    //   render(createElement) {
    //     return createElement('script', { defer: {}, src: this.src })
    //   },
    //   props: {
    //     src: { type: String, required: true }
    //   }
    // }
  }
}
</script>

<style>

</style>
