const stockRecordRouter = [
  {
    path: '/stock-record',
    component: () => import('@/views/stock-record')
  },
  {
    path: '/stock-record-detail',
    component: () => import('@/views/stock-record/detail')
  },
  {
    path: '/stock-record-data-analysis',
    component: () => import('@/views/stock-record/data-analysis')
  }
]

export default stockRecordRouter
