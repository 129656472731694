const salesRankRouter = [
  {
    path: '/sales-rank',
    component: () => import('@/views/sales-rank')
  },
  {
    path: '/sales-rank-client',
    component: () => import('@/views/sales-rank/client')
  },
  {
    path: '/sales-rank-supplier',
    component: () => import('@/views/sales-rank/supplier')
  },
  {
    path: '/sales-rank-brand',
    component: () => import('@/views/sales-rank/brand')
  }
]

export default salesRankRouter
