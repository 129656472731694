const stocktakingPlanRouter = [
  {
    path: '/stocktaking-plan',
    component: () => import('@/views/stocktaking-plan')
  },
  {
    path: '/stocktaking-plan-report',
    component: () => import('@/views/stocktaking-plan-report')
  },
  {
    path: '/stocktaking-plan-history',
    component: () => import('@/views/stocktaking-plan/history')
  }
]

export default stocktakingPlanRouter
