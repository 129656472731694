const afterAcceptRouter = [
  {
    path: '/after-accept',
    component: () => import('@/views/after-accept')
  },
  {
    path: '/after-accept-list',
    component: () => import('@/views/after-accept/list')
  },
  {
    path: '/after-accept-detail',
    component: () => import('@/views/after-accept/detail')
  },
  {
    path: '/after-accept-record',
    component: () => import('@/views/after-accept/record')
  }
]

export default afterAcceptRouter
