import dayjs from 'dayjs'

const tokenKey = 'jwt_auth'

export default {

  get() {
    return JSON.parse(localStorage.getItem(tokenKey))
  },
  set(data) {
    localStorage.setItem(tokenKey, JSON.stringify(data))
  },
  rm() {
    localStorage.removeItem(tokenKey)
  },
  isValid() {
    const token = this.get()
    if (!token || token.expires_in < dayjs().unix()) {
      return false
    }

    return true
  }

}
