const clientVisitRouter = [
  {
    path: '/client-visit',
    component: () => import('@/views/client-visit')
  },
  {
    path: '/client-visit-history',
    component: () => import('@/views/client-visit/history')
  }
]

export default clientVisitRouter
