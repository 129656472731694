import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'
import './permission'
import './styles/index.scss'
import 'amfe-flexible'
import mixin from './mixin'
import { Toast } from 'vant'
import VueAMap from 'vue-amap'
import { optionalChaining } from '@/utils'
import VueClipboard from 'vue-clipboard2'

setTimeout(() => {
  Vue.use(VueAMap)
  VueAMap.initAMapApiLoader({
    // 高德的key
    key: '2233e1ccc7010280ab5ddc3b001e1755',
    // 插件集合
    plugin: ['AMap.Geolocation', 'AMap.Autocomplete', 'AMap.OverView', 'AMap.MapType', 'AMap.CircleEditor'],
    // 高德 sdk 版本，默认为 1.4.4
    v: '1.4.4'
  })
}, 0)
Vue.config.productionTip = false
Toast.setDefaultOptions({
  className: 'hj-toast',
  forbidClick: true,
  duration: 1500
})

Vue.mixin(mixin)

Vue.prototype.$eventBus = new Vue()
Vue.prototype.$$ = optionalChaining

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
