const orderNextRouter = [
  {
    path: '/order-next',
    component: () => import('@/views/order-next')
  },
  {
    path: '/order-next-detail',
    component: () => import('@/views/order-next/detail')
  }
]

export default orderNextRouter
