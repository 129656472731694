const commissionRouter = [
  {
    path: '/commission',
    component: () => import('@/views/commission')
  },
  {
    path: '/commission2',
    component: () => import('@/views/commission/index2')
  },
  {
    path: '/commission-detail',
    component: () => import('@/views/commission/detail')
  }
]

export default commissionRouter
