const myCommissionRouter = [
  {
    path: '/my-commission',
    component: () => import('@/views/my-commission')
  },
  {
    path: '/my-commission/promote-sales-detail',
    component: () => import('@/views/my-commission/promote-sales-detail')
  },
  {
    path: '/my-commission/commission',
    component: () => import('@/views/my-commission/commission')
  },
  {
    path: '/my-commission/commission-detail',
    component: () => import('@/views/my-commission/commission-detail')
  }
]

export default myCommissionRouter
