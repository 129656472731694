import Vue from 'vue'
import VueRouter from 'vue-router'
import vueg from 'vueg'

let routers = []
const files = require.context('./modules', true, /.js$/)
files.keys().forEach(key => {
  routers = routers.concat(files(key).default)
})

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/index'),
    children: [
      {
        path: '/home',
        component: () => import('@/views/home')
      },
      {
        path: '/mine',
        component: () => import('@/views/mine')
      },
      {
        path: '/warehouse-area',
        component: () => import('@/views/warehouse-area')
      },
      ...routers
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404')
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  routes
  // scrollBehavior: () => ({ y: 0 })
})

// 路由转场动画
Vue.use(vueg, router) // 传入实例化后的router

export default router
