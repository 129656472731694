const deliveryOrderRouter = [
  {
    path: '/delivery-order',
    component: () => import('@/views/delivery-order')
  },
  {
    path: '/delivery-order-payment',
    component: () => import('@/views/delivery-order/payment')
  },
  {
    path: '/delivery-order-goods',
    component: () => import('@/views/delivery-order/goods')
  },
  {
    path: '/set-location',
    component: () => import('@/views/delivery-order/set-position')
  },
  {
    path: '/promote-sales-order',
    component: () => import('@/views/delivery-order/promote-sales-order')
  },
  {
    path: '/aod-detail',
    component: () => import('@/views/delivery-order/aod-detail')
  },
  {
    path: '/aod-list',
    component: () => import('@/views/delivery-order/aod-list')
  },
  {
    path: '/unpaid-receive',
    component: () => import('@/views/delivery-order/unpaid-receive')
  }
]

export default deliveryOrderRouter
