const businessTakeRouter = [
  {
    path: '/business-take',
    component: () => import('@/views/business-take')
  },
  {
    path: '/business-take-order',
    component: () => import('@/views/business-take/order')
  },
  {
    path: '/business-take-detail',
    component: () => import('@/views/business-take/detail')
  }
]

export default businessTakeRouter
