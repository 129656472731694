const salesmanOrderRouter = [
  {
    path: '/salesman-order',
    component: () => import('@/views/salesman-order')
  },
  {
    path: '/salesman-order-client',
    component: () => import('@/views/salesman-order/client')
  },
  {
    path: '/salesman-order-goods',
    component: () => import('@/views/salesman-order/goods')
  }
]

export default salesmanOrderRouter
