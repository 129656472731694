const billRouter = [
  {
    path: '/bill',
    component: () => import('@/views/bill')
  },
  {
    path: '/bill-list',
    component: () => import('@/views/bill/list')
  },
  {
    path: '/bill-detail',
    component: () => import('@/views/bill/detail')
  },
  {
    path: '/bill-payment-detail',
    component: () => import('@/views/bill/payment-detail')
  },
  {
    path: '/bill-client-payment',
    component: () => import('@/views/bill/client-payment')
  },
  {
    path: '/bill-payment-online',
    component: () => import('@/views/bill/payment-online')
  }
]

export default billRouter
