var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('remote-css', {
    attrs: {
      "href": "https://at.alicdn.com/t/c/font_2101868_s1ud8ba97c.css"
    }
  }), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }