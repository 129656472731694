const loadReviewRouter = [
  {
    path: '/load-review',
    component: () => import('@/views/load-review')
  },
  {
    path: '/load-review-client',
    component: () => import('@/views/load-review/client')
  },
  {
    path: '/load-review-detail',
    component: () => import('@/views/load-review/detail')
  }
]

export default loadReviewRouter
