import dayjs from 'dayjs'

// 获取链接中的参数
export function getUrlParams(name) {
  // eslint-disable-next-line no-sparse-arrays
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

// 当前时间戳
export function time(datestr = null) {
  if (datestr) { return dayjs(datestr).unix() }
  return dayjs().unix()
}

// date对象转成日期时间
export function toDatetimeString(date) {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}

// date对象转成日期
export function toDateString(date) {
  return dayjs(date).format('YYYY-MM-DD')
}

export function toDateStringMonth(date) {
  return dayjs(date).format('YYYY-MM')
}

export function toDateStringYear(date) {
  return dayjs(date).format('YYYY')
}

// 总数转件数和个数
export function volumeToUnits(volume, size) {
  const largeVolume = size ? Math.floor(volume / size) : 0
  const leastVolume = size ? volume % size : volume
  return { largeVolume, leastVolume }
}

// 件数和个数转总数
export function unitsToVolume(largeVolume, leastVolume, size) {
  if (largeVolume > 0) { return Number(largeVolume) * Number(size) + Number(leastVolume) } else { return leastVolume }
}

// 当前日期时间
export function now(formatStr = null) {
  if (formatStr) { return dayjs().format(formatStr) } else { return dayjs().format('YYYY-MM-DD HH:mm:ss') }
}

// 当前日期时间
export function currentDate(formatStr = null) {
  if (formatStr) { return dayjs().format(formatStr) } else { return dayjs().format('YYYY-MM-DD') }
}

// 当前月份
export function currentMonth(formatStr = null) {
  if (formatStr) { return dayjs().format(formatStr) } else { return dayjs().format('YYYY-MM') }
}

// 数组对象排序
export function sortByKey(key, type = 'asc') {
  return function(a, b) {
    const x = a[key] ? 1 : 0
    const y = b[key] ? 1 : 0
    return type === 'desc' ? y - x : x - y
  }
}

// 判断手机是否是iphonex
export function isIPhoneX(fn) {
  var u = navigator.userAgent
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  if (isIOS) {
    return screen.height === 812 && screen.width === 375
  } else {
    return false
  }
}

// 不满补0
export function strpad(str, length, pad = '0', direct = 'left') {
  let join = ''
  if (direct === 'left') {
    join = Array(length).join(pad) + str
  } else {
    join = str + Array(length).join(pad)
  }
  return join.slice(-length)
}

export function isWechat() {
  const ua = navigator.userAgent.toLowerCase() || window.navigator.userAgent.toLowerCase()
  // // 判断user-agent
  return /MicroMessenger/i.test(ua)
}

export function getPlatform() {
  const ua = navigator.userAgent.toLowerCase() || window.navigator.userAgent.toLowerCase()
  const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(ua) // 苹果家族
  const isAndroid = /(android|nexus)/i.test(ua) // 安卓家族
  if (isIOS) { return 'iOS' }
  if (isAndroid) { return 'isAndroid' }
  return null
}

export function getRequestPath() {
  const split_path = location.href.split('/#/')[1]
  let path
  if (split_path.indexOf('?') === -1) {
    path = split_path
  } else {
    path = split_path.split('?')[0]
  }
  return path
}

// 使template支持可选链操作符
export function optionalChaining(obj, ...rest) {
  let tmp = obj
  for (const key in rest) {
    const name = rest[key]
    tmp = tmp?.[name]
  }

  return tmp || ''
}
