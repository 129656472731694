const clientReportRouter = [
  {
    path: '/client-report',
    component: () => import('@/views/client-report')
  },
  {
    path: '/client-report-detail',
    component: () => import('@/views/client-report/detail')
  }
]

export default clientReportRouter
