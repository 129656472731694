import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showTabbar: true,
    userInfo: null,
    keepAlives: [],
    filterCategory: null,
    pickNumber: 10
  },
  mutations: {
    showTabbar(state, show) {
      state.showTabbar = show
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setDefaultWarehouse(state, warehouse) {
      state.defaultWarehouse = warehouse
    },
    setPickNumber(state, pickNumber) {
      state.pickNumber = pickNumber
    },
    setKeepAlive(state, keepName) {
      const index = state.keepAlives.findIndex(name => name === keepName)
      if (index === -1) { state.keepAlives.push(keepName) }
    },
    rmKeepAlive(state, keepName) {
      const index = state.keepAlives.findIndex(name => name === keepName)
      if (index !== -1) { state.keepAlives.splice(index, 1) }
    },
    clearKeepAlives(state) {
      state.keepAlives = []
    },
    setFilterCategory(state, category) {
      state.filterCategory = category
    },
    rmFilterCategory(state) {
      state.filterCategory = null
    }
  },
  plugins: [createPersistedState()]
})
