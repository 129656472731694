const billCheckRouter = [
  {
    path: '/bill-check',
    component: () => import('@/views/bill-check')
  },
  {
    path: '/bill-check-detail',
    component: () => import('@/views/bill-check/detail')
  },
  {
    path: '/bill-check-unpaid-order',
    component: () => import('@/views/bill-check/unpaid-order')
  },
  {
    path: '/bill-check-order',
    component: () => import('@/views/bill-check/order')
  },
  {
    path: '/bill-check-order-goods',
    component: () => import('@/views/bill-check/order-goods')
  },
  {
    path: '/bill-check-after-order',
    component: () => import('@/views/bill-check/after-order')
  },
  {
    path: '/bill-check-after-goods',
    component: () => import('@/views/bill-check/after-goods')
  },
  {
    path: '/bill-check-payment-online',
    component: () => import('@/views/bill-check/payment-online')
  }
]

export default billCheckRouter
