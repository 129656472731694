const toBePaymentRouter = [
  {
    path: '/supplier-unpaid',
    component: () => import('@/views/supplier-unpaid')
  },
  {
    path: '/supplier-unpaid-detail',
    component: () => import('@/views/supplier-unpaid/detail')
  },
  {
    path: '/supplier-unpaid-select',
    component: () => import('@/views/supplier-unpaid/select')
  },
  {
    path: '/supplier-unpaid-iou',
    component: () => import('@/views/supplier-unpaid/iou')
  },
  {
    path: '/supplier-unpaid-payment',
    component: () => import('@/views/supplier-unpaid/payment')
  }
]

export default toBePaymentRouter
