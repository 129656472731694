const clientUnpaidRouter = [
  {
    path: '/client-unpaid',
    component: () => import('@/views/client-unpaid')
  },
  {
    path: '/client-unpaid-detail',
    component: () => import('@/views/client-unpaid/detail')
  },
  {
    path: '/client-unpaid-select',
    component: () => import('@/views/client-unpaid/select')
  },
  {
    path: '/client-unpaid-iou',
    component: () => import('@/views/client-unpaid/iou')
  },
  {
    path: '/client-unpaid-iou-receive',
    component: () => import('@/views/client-unpaid/iou-receive')
  },
  {
    path: '/client-unpaid-receive',
    component: () => import('@/views/client-unpaid/unpaid-receive')
  }
]

export default clientUnpaidRouter
