const financeExpenseRouter = [
  {
    path: '/finance-expense',
    component: () => import('@/views/dialy-expense')
  },
  {
    path: '/finance-expense-list',
    component: () => import('@/views/dialy-expense/list')
  }
]

export default financeExpenseRouter
