import { Toast, Dialog } from 'vant'
export default {
  methods: {
    success(msg) {
      Toast.success(msg)
    },
    fail(msg) {
      Toast.fail(msg)
    },
    beginLoad() {
      Toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
      })
    },
    endLoad() {
      Toast.clear()
    },
    confirm(message, title = '提示', options = {
      confirmButtonColor: '#1989fa',
      confirmButtonText: '确定'
    }) {
      return new Promise((resolve, reject) => {
        Dialog.confirm({
          title,
          message,
          ...options
        })
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    }
  }
}
